import { useStaticQuery, graphql } from "gatsby"

//Speisekarte-2.jpg
export const useImpressumImage = () => {
  const { impressumImage } = useStaticQuery(
    graphql`
      query ImpressumImage {
        impressumImage: file(relativePath: { eq: "Kontakt.jpg" }) {
          childImageSharp {
            fluid(quality: 40, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )
  return impressumImage
}
